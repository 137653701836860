import React from 'react'

const KLADKofi = () => {
  return ( 
    <div className='text-center'>
      <p className='text-muted'>This tool was made by KLAD. If you found it useful, please consider supporting me on Ko-fi.</p>
      <a href='https://ko-fi.com/A742AXC' target='_blank' rel="noreferrer"><img height='36' style={{border: "0px", height: "36px"}} src='https://cdn.ko-fi.com/cdn/kofi5.png?v=3' border='0' alt='Buy Me a Coffee at ko-fi.com'/></a>
    </div>
  );
}
 
export default KLADKofi;
import React, { Component } from 'react'
import {
  Nav,
  Tab,
  Form,
  Row,
  Col,
  InputGroup,
  Card,
  Button,
} from 'react-bootstrap'
import Layout from '../components/layout'
import KLADKofi from '../components/klad-kofi';

class TermsOfUseGenerator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      info: {
        vbName: "",
        authorName: "",
        authorContact: "",
      },
      attrib: {
        vb: "",
        author: "",
      },
      usage: {
        sex: "",
        violent: "",
        pol: "",
        rel: "",
      },
      artwork: {
        otherChar: "",
        reuse: "",
        filename: "",
      },
      commercial: {
        rule: "",
        purchaseLink: "",
        earnLimit: "",
      },
      edit: {
        audio: "",
        config: "",
        port: "",
        prod: "",
        prodFolder: "",
      },
      redist: {
        before: "",
        after: "",
        render: "",
      }
    }

    this.state.fullText = this.generate(this.state)
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e, subform, name) {
    const value = e.target.value;
    let newState = {
      [name]: value
    }
    this.setState(prevState => {
      // Clear extra fields when option is deselected
      if (subform === 'artwork' && name === 'reuse' && value !== "yes") {
        newState.filename = ""
      } else if (subform === "commercial") {
        if (name === "rule") {
          if (value !== "purchase") {
            newState.purchaseLink = ""
          } else if (value !== "yesLimit") {
            newState.earnLimit = ""
          }
        } else if (name === "earnLimit") {
          if (!e.target.validity.valid) {
            newState.earnLimit = ""
          } else {
            newState.earnLimit = value.replace(/^(\d+\.?\d{0,2}).*$/, "$1")
          }
        }
      } else if (subform === "edit" && name === "prod" && value !== "limit") {
        newState.prodFolder = ""
      }
      return {
        [subform]: { // Merge old subform with new element in subform
          ...prevState[subform],
          ...newState,
        }
      }
    });

    this.setState(prevState => {
      return { fullText: this.generate(prevState) };
    });
  }

  options = {
    attrib: {
      vb: {
        "": "",
        yes: "When publishing work using this voicebank, you are required to state the name of this voicebank.",
        no: "When publishing work using this voicebank, it is not necessary to state the name of this voicebank.",
      },
      author: {
        "": "",
        yes: "You are required to state the name of this voicebank's author.",
        no: "It is not necessary to state the name of this voicebank's author.",
      }
    },
    usage: {
      sex: {
        "": "",
        no: "Do not create sexual content.",
        perm: "Seek author permission to create sexual content.",
        yes: "You are free to create sexual content."
      },
      violent: {
        "": "",
        no: "Do not create violent content.",
        perm: "Seek author permission to create violent content.",
        yes: "You are free to create violent content."
      },
      pol: {
        "": "",
        no: "Do not create political content.",
        perm: "Seek author permission to create political content.",
        yes: "You are free to create political content."
      },
      rel: {
        "": "",
        no: "Do not create religious content.",
        perm: "Seek author permission to create religious content.",
        yes: "You are free to create religious content."
      }
    },
    artwork: {
      otherChar: {
        "": "",
        no: "Visual depictions should be clearly recognizable as the voicebank character. This voicebank cannot be used in conjunction with artwork of other characters.",
        yes: "Artwork does not need to depict the voicebank character. This voicebank can be used in conjunction with artwork of other characters.",
      },
      reuse: {
        "": "",
        no: "Existing artwork of the voicebank character cannot be used without prior permission from the individual artists.",
        yes: "Existing artwork of the voicebank character cannot be used without prior permission from the individual artists. However, when publishing work featuring the voicebank, you may freely use the file \"#filename\".",
      },
    },
    commercial: {
      rule: {
        "": "",
        no: "You cannot use this voicebank commercially. All usage of this voicebank must be strictly non-commercial.",
        perm: "Contact the author for individual approval to use this voicebank commercially.",
        purchase: "Purchase a commercial usage license at #purchaseLink",
        yes: "",
        yesLimit: "You may use this voicebank commercially if the expected total earnings from all projects involving this voicebank are less than or equal to $#earnLimit.",
        yesAll: "You are free to use this voicebank commercially."
      },
    },
    edit: {
      audio: {
        "": "",
        no: "You cannot edit this voicebank's audio files.",
        clean: "You are only permitted to clean or enhance this voicebank's audio. You cannot edit the audio in any other way.",
        pitch: "You can edit this voicebank's audio in any way, except for pitch or formant manipulation.",
        yes: "You are free to edit the audio files of this voicebank in any way.",
      },
      config: {
        "": "",
        no: "You cannot edit any configuration files, such as oto.ini or FRQ files.",
        yes: "You are free to edit any configuration files, such as oto.ini or FRQ files.",
      },
      port: {
        "": "",
        no: "You can only use this voicebank in UTAU and similar singing synthesizers that do not require any edits to the voicebank.",
        yes: "You are free to edit, convert, or reconfigure this voicebank for any singing synthesizer.",
      },
      prod: {
        "": "",
        no: "The contents of this voicebank can only be used in permitted singing synthesizers. You cannot use it in any other way, such as for music production or video production.",
        limit: "The contents of this voicebank can only be used in permitted singing synthesizers. However, you are free to use audio from the \"#folder\" folder in other ways, such as for music production or video production.",
        yes: "You are permitted to use the contents of this voicebank outside of singing synthesizers, such as for music production or video production."
      },
    },
    redist: {
      before: {
        "": "",
        no: "Before this voicebank is discontinued, you cannot redistribute it.",
        transfer: "Before this voicebank is discontinued, you may transfer or resell the entire unedited voicebank to one other person for the original price or less, then permanently delete your own copy.",
        unedit: "Before this voicebank is discontinued, you may only redistribute the entire unedited voicebank. You must correctly attribute the voicebank's name and author.",
        config: "Before this voicebank is discontinued, you may only share configuration files, such as oto.ini or FRQ files.",
        yes: "Before this voicebank is discontinued, you are free to redistribute part or all of the voicebank. You must correctly attribute the voicebank's name and author, and clearly state any changes you have made."
      },
      after: {
        "": "",
        no: "After this voicebank is discontinued, you cannot redistribute it.",
        transfer: "After this voicebank is discontinued, you may transfer or resell the entire unedited voicebank to one other person for the original price or less, then permanently delete your own copy.",
        unedit: "After this voicebank is discontinued, you may only redistribute the entire unedited voicebank. You must correctly attribute the voicebank's name and author.",
        config: "After this voicebank is discontinued, you may only share configuration files, such as oto.ini or FRQ files.",
        yes: "After this voicebank is discontinued, you are free to redistribute part or all of the voicebank. You must correctly attribute the voicebank's name and author, and clearly state any changes you have made."
      },
      render: {
        "": "",
        no: "You cannot share rendered singing vocals created with this voicebank.",
        yes: "You are free to share rendered singing vocals created with this voicebank."
      },
    }
  }

  generate(state) {
    let template = `#vbName
by #authorName

#authorContact

Voicebank Terms of Use

Attribution
#attribVb
#attribAuth
When attributing the name or author of this voicebank, you must write the names exactly as written above. You cannot call the voicebank by any other name, nor can you attribute the voicebank to any other author.

Usage Content
#usageSex
#usageViolent
#usagePol
#usageRel
The following types of content are unconditionally prohibited:
- Criminal or illegal content
- Explicit underage sexual content
- Bigotry and hateful content
- Violations of UTAU's Usage Policy
#authorName reserves the right to request takedown of published content at any time, and users are expected to comply.

Character and Artwork
Published work using the voicebank may or may not include visual depictions of the voicebank character.
#otherChar
#reuseArt

Commercial Use
#commercial
You cannot use this voicebank or any included assets in projects involving non-fungible tokens (NFTs) or cryptocurrency.

Editing
#editAudio
#editConfig
#editPort
#editProd

Redistribution
#redistBefore
#redistAfter
#redistRender

Disclaimer
#authorName holds no responsibility to any incidents, damage, or loss by the user from downloading or using the voicebank or character.
#authorName holds no responsibility to any incidents, damage, or loss that occurs to any third party as a result of usage of the voicebank or voicebank character.
These terms of use were originally generated with https://tools.tubs.wtf/vbtougen and may or may not include additional edits.
`;

    function escapeHash(object) {
      let clone = {...object}
      const entries = Object.entries(clone);

      entries.forEach(([key, value]) => {
        if (typeof value === "object") {
          clone[key] = escapeHash(value);
        } else {
          clone[key] = value.replaceAll("#", "#-");
        }
      });
      return clone;
    };

    escapeHash(state)

    const primaryMap = {
      "#vbName": state.info.vbName,
      "#authorName": state.info.authorName,
      "#authorContact": state.info.authorContact,
      "#attribVb": this.options.attrib.vb[state.attrib.vb],
      "#attribAuth": this.options.attrib.author[state.attrib.author],
      "#usageSex": this.options.usage.sex[state.usage.sex],
      "#usageViolent": this.options.usage.violent[state.usage.violent],
      "#usagePol": this.options.usage.pol[state.usage.pol],
      "#usageRel": this.options.usage.rel[state.usage.rel],
      "#otherChar": this.options.artwork.otherChar[state.artwork.otherChar],
      "#reuseArt": this.options.artwork.reuse[state.artwork.reuse],
      "#commercial": this.options.commercial.rule[state.commercial.rule],
      "#editAudio": this.options.edit.audio[state.edit.audio],
      "#editConfig": this.options.edit.config[state.edit.config],
      "#editPort": this.options.edit.port[state.edit.port],
      "#editProd": this.options.edit.prod[state.edit.prod],
      "#redistBefore": this.options.redist.before[state.redist.before],
      "#redistAfter": this.options.redist.after[state.redist.after],
      "#redistRender": this.options.redist.render[state.redist.render],
    }

    const secondaryMap = {
      "#filename": state.artwork.filename,
      "#purchaseLink": state.commercial.purchaseLink,
      "#earnLimit": state.commercial.earnLimit,
      "#folder": state.edit.prodFolder,
    }

    function replaceParallel(string, map){
      var regex = new RegExp(Object.keys(map).join("|"),"gi");
  
      return string.replace(regex, function(matched){
          return map[matched];
      });
    }

    template = replaceParallel(template, primaryMap)
    template = replaceParallel(template, secondaryMap)

    template = template.replaceAll("#-", "#")

    while(template.includes("\n\n\n")){
      template = template.replaceAll("\n\n\n", "\n\n")
    }

    return template
  }

  render() {
    return (
      <Layout pageTitle="UTAU Voicebank Terms of Use Generator" 
        pageLang="en_US"
        pageDesc="Generate a readme for your UTAU voicebank with terms for attribution, usage content, character and artwork, commercial use, editing, and redistribution">
        <noscript>
          Please enable JavaScript to use this generator.
        </noscript>
        <div className="noscript-hide">
          <MultitabForm state={this.state} onChange={this.handleChange} />
          <Preview content={this.state.fullText} />
          <KLADKofi/>
        </div>
      </Layout>
    );
  }
}

export default TermsOfUseGenerator;

function MultitabForm(props) {
  const subForms = [
    {
      form: <InfoForm />,
      id: "info",
      name: "Basic Information",
    },
    {
      form: <AttribForm />,
      id: "attrib",
      name: "Attribution",
    },
    {
      form: <UsageForm />,
      id: "usage",
      name: "Usage Content",
    },
    {
      form: <ArtworkForm />,
      id: "artwork",
      name: "Character and Artwork",
    },
    {
      form: <CommercialForm />,
      id: "commercial",
      name: "Commercial Use",
    },
    {
      form: <EditForm />,
      id: "edit",
      name: "Editing",
    },
    {
      form: <RedistForm />,
      id: "redist",
      name: "Redistribution",
    },
  ]
  return (
    <Tab.Container defaultActiveKey="info">
      <Nav variant="pills" className="mb-3">
        {subForms.map(subform => {
          return (
            <Nav.Item>
              <Nav.Link eventKey={subform.id} href="#">
                {subform.name}
              </Nav.Link>
            </Nav.Item>
          )
        })}
      </Nav>
      <Tab.Content className="mb-3">
        {subForms.map(subform => {
          return (
            <Tab.Pane eventKey={subform.id}>
              {React.cloneElement(subform.form, {
                subform: subform.id,
                state: props.state[subform.id],
                onChange: props.onChange,
              })}
            </Tab.Pane>
          )
        })}
      </Tab.Content>
    </Tab.Container>
  )
}

function InfoForm(props) {
  return (
    <Form>
      <Form.Group as={Row} className="justify-content-center mb-2">
        <Form.Label column sm={4} md={3} xl={2} className="text-sm-end">
          Voicebank name
        </Form.Label>
        <Col sm={6} lg={4}>
          <Form.Control type="text"
            value={props.state.vbName}
            onChange={(e) => props.onChange(e, props.subform, "vbName")} />
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="justify-content-center mb-2">
        <Form.Label column sm={4} md={3} xl={2} className="text-sm-end">
          Author name
        </Form.Label>
        <Col sm={6} lg={4}>
          <Form.Control type="text"
            value={props.state.authorName}
            onChange={(e) => props.onChange(e, props.subform, "authorName")} />
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="justify-content-center">
        <Form.Label column sm={4} md={3} xl={2} className="text-sm-end">
          Author contact info
        </Form.Label>
        <Col sm={6} lg={4}>
          <Form.Control as="textarea" rows={3}
            placeholder="Email, Twitter, etc."
            value={props.state.authorContact}
            onChange={(e) => props.onChange(e, props.subform, "authorContact")} />
        </Col>
      </Form.Group>
    </Form>
  )
}

function AttribForm(props) {
  return (
    <div>
      <p>Does the user need to specify which voicebank was used and who made it?</p>
      <Form className="row">
        <Form.Group as={Col} md={6}>
          <h5>Attributing voicebank name</h5>
          <Form.Check type="radio"
            name="attribVb"
            id="attribVbYes"
            value="yes"
            label="Always required"
            onChange={(e) => props.onChange(e, props.subform, "vb")} />
          <Form.Check type="radio"
            name="attribVb"
            id="attribVbNo"
            value="no"
            label="Not required"
            onChange={(e) => props.onChange(e, props.subform, "vb")} />
        </Form.Group>
        <Form.Group as={Col} md={6}>
          <h5>Attributing author name</h5>
          <Form.Check type="radio"
            name="attribAuth"
            id="attribAuthYes"
            value="yes"
            label="Always required"
            onChange={(e) => props.onChange(e, props.subform, "author")} />
          <Form.Check type="radio"
            name="attribAuth"
            value="no"
            id="attribAuthNo"
            label="Not required"
            onChange={(e) => props.onChange(e, props.subform, "author")} />
        </Form.Group>
      </Form>
    </div>
  )
}

function UsageForm(props) {
  return (
    <div>
      <p>What types of content are allowed?</p>
      <Form className="row">
        <Form.Group as={Col} md={6} lg={3} className="mb-3">
          <h5>Sexual</h5>
          <Form.Check type="radio"
            name="sex"
            id="sexNo"
            value="no"
            label="Prohibited"
            onChange={(e) => props.onChange(e, props.subform, "sex")} />
          <Form.Check type="radio"
            name="sex"
            id="sexPerm"
            value="perm"
            label="With author permission"
            onChange={(e) => props.onChange(e, props.subform, "sex")} />
          <Form.Check type="radio"
            name="sex"
            id="sexYes"
            value="yes"
            label="Allowed"
            onChange={(e) => props.onChange(e, props.subform, "sex")} />
        </Form.Group>
        <Form.Group as={Col} md={6} lg={3} className="mb-3">
          <h5>Violent</h5>
          <Form.Check type="radio"
            name="violent"
            id="violentNo"
            value="no"
            label="Prohibited"
            onChange={(e) => props.onChange(e, props.subform, "violent")} />
          <Form.Check type="radio"
            name="violent"
            id="violentPerm"
            value="perm"
            label="With author permission"
            onChange={(e) => props.onChange(e, props.subform, "violent")} />
          <Form.Check type="radio"
            name="violent"
            id="violentYes"
            value="yes"
            label="Allowed"
            onChange={(e) => props.onChange(e, props.subform, "violent")} />
        </Form.Group>
        <Form.Group as={Col} md={6} lg={3} className="mb-3">
          <h5>Political</h5>
          <Form.Check type="radio"
            name="pol"
            id="polNo"
            value="no"
            label="Prohibited"
            onChange={(e) => props.onChange(e, props.subform, "pol")} />
          <Form.Check type="radio"
            name="pol"
            id="polPerm"
            value="perm"
            label="With author permission"
            onChange={(e) => props.onChange(e, props.subform, "pol")} />
          <Form.Check type="radio"
            name="pol"
            id="polYes"
            value="yes"
            label="Allowed"
            onChange={(e) => props.onChange(e, props.subform, "pol")} />
        </Form.Group>
        <Form.Group as={Col} md={6} lg={3} className="mb-3">
          <h5>Religious</h5>
          <Form.Check type="radio"
            name="rel"
            id="relNo"
            value="no"
            label="Prohibited"
            onChange={(e) => props.onChange(e, props.subform, "rel")} />
          <Form.Check type="radio"
            name="rel"
            id="relPerm"
            value="perm"
            label="With author permission"
            onChange={(e) => props.onChange(e, props.subform, "rel")} />
          <Form.Check type="radio"
            name="rel"
            id="relYes"
            value="yes"
            label="Allowed"
            onChange={(e) => props.onChange(e, props.subform, "rel")} />
        </Form.Group>
      </Form>
      <h5>Prohibited in all voicebanks</h5>
      <ul>
        <li>Criminal or illegal content</li>
        <li>Explicit underage sexual content</li>
        <li>Bigotry and hateful content</li>
        <li>Violations of UTAU's Usage Policy</li>
        <li>Involvement with non-fungible tokens (NFTs) or cryptocurrency</li>
      </ul>
    </div>
  )
}

function ArtworkForm(props) {
  const filename = props.state.reuse === "yes" ? (
    <Form.Control type="text"
      placeholder="image.png"
      value={props.state.filename}
      onChange={(e) => props.onChange(e, props.subform, "filename")} />
  ) : (
    <Form.Control type="text"
      disabled
      value={props.state.filename}
      placeholder="image.png" />
  )
  return (
    <div>
      <p>What art can be used with this voicebank?</p>
      <Form className="row">
        <Form.Group as={Col} lg={6} className="mb-3">
          <h5>Characters other than voicebank character</h5>
          <Form.Check type="radio"
            name="otherChar"
            id="otherNo"
            value="no"
            label="Prohibited"
            onChange={(e) => props.onChange(e, props.subform, "otherChar")} />
          <Form.Check type="radio"
            name="otherChar"
            id="otherYes"
            value="yes"
            label="Allowed"
            onChange={(e) => props.onChange(e, props.subform, "otherChar")} />
        </Form.Group>
        <Form.Group as={Col} lg={6} className="mb-3">
          <h5>Reusing art</h5>
          <Form.Check type="radio"
            name="reuseArt"
            id="reuseNo"
            value="no"
            label="No art can be reused"
            onChange={(e) => props.onChange(e, props.subform, "reuse")} />
          <Form.Check type="radio"
            name="reuseArt"
            id="reuseYes"
            value="yes"
            label="This image file only"
            onChange={(e) => props.onChange(e, props.subform, "reuse")} />
          <Col xs={6} className="ms-4">
            {filename}
          </Col>
        </Form.Group>
      </Form>
    </div>
  )
}

function CommercialForm(props) {
  const purchaseLink = props.state.rule === "purchase" ? (
    <Form.Control type="url" placeholder="Purchase link"
      value={props.state.purchaseLink}
      onChange={(e) => props.onChange(e, props.subform, "purchaseLink")} />
  ) : (
    <Form.Control type="url" placeholder="Purchase link"
      disabled
      value={props.state.purchaseLink} />
  )
  const earnLimit = props.state.rule === "yesLimit" ? (
    <Form.Control type="tel"
      pattern="^[0-9]\d*\.?\d*$"
      value={props.state.earnLimit}
      onChange={(e) => props.onChange(e, props.subform, "earnLimit")} />
  ) : (
    <Form.Control type="tel"
      disabled
      value={props.state.earnLimit} />
  )
  const freeUseOptions = props.state.rule.startsWith("yes") ? (
    <Form.Group className="ms-4">
      <Form.Check type="radio"
        name="commercialYesOpt"
        value="yesLimit"
        id="commercialYesLimit"
        label="Expected earnings under this amount only"
        onChange={(e) => props.onChange(e, props.subform, "rule")} />
      <Col xs={6} className="ms-4">
        <InputGroup>
          <InputGroup.Text>$</InputGroup.Text>
          {earnLimit}
        </InputGroup>
      </Col>
      <Form.Check type="radio"
        name="commercialYesOpt"
        id="commercialYesAll"
        value="yesAll"
        label="All commercial work"
        onChange={(e) => props.onChange(e, props.subform, "rule")} />
    </Form.Group>
  ) : (
    <Form.Group className="ms-4">
      <Form.Check type="radio"
        disabled
        checked={false}
        label="Expected earnings under this amount only" />
      <Col xs={6} className="ms-4">
        <InputGroup>
          <InputGroup.Text>$</InputGroup.Text>
          <Form.Control type="tel"
            disabled
            value={props.state.earnLimit} />
        </InputGroup>
      </Col>
      <Form.Check type="radio"
        disabled
        checked={false}
        label="All commercial work" />
    </Form.Group>
  )
  return (
    <div>
      <p>Can users earn money using this voicebank?</p>
      <Form>
        <Form.Check type="radio"
          name="commercialOpt"
          id="commercialNo"
          value="no"
          label="No commercial work"
          onChange={(e) => props.onChange(e, props.subform, "rule")} />
        <Form.Check type="radio"
          name="commercialOpt"
          id="commercialPerm"
          value="perm"
          label="Contact the author for case-by-case approval"
          onChange={(e) => props.onChange(e, props.subform, "rule")} />
        <Form.Check type="radio"
          name="commercialOpt"
          value="purchase"
          id="commercialPurchsae"
          label="Purchasable commercial usage license"
          onChange={(e) => props.onChange(e, props.subform, "rule")} />
        <Col md={6} className="ms-4">
          {purchaseLink}
        </Col>
        <Form.Check type="radio"
          name="commercialOpt"
          id="commercialYes"
          value="yes"
          label="Free without permission"
          onChange={(e) => props.onChange(e, props.subform, "rule")} />
        {freeUseOptions}
      </Form>
    </div>
  )
}

function EditForm(props) {
  const folder = props.state.prod === "limit" ? (
    <Form.Control type="text" placeholder="folder name"
      value={props.state.prodFolder}
      onChange={(e) => props.onChange(e, props.subform, "prodFolder")} />
  ) : (
    <Form.Control type="text" placeholder="folder name"
      disabled
      value={props.state.prodFolder} />
  )
  return (
    <div>
      <p>How can this voicebank be edited?</p>
      <Form className="row">
        <Form.Group as={Col} md={6} lg={3}>
          <h5>Audio for UTAU</h5>
          <Form.Check type="radio"
            name="editAudio"
            id="editAudioNo"
            value="no"
            label="No editing"
            onChange={(e) => props.onChange(e, props.subform, "audio")} />
          <Form.Check type="radio"
            name="editAudio"
            id="editAudioClean"
            value="clean"
            label="No editing except sample cleaning"
            onChange={(e) => props.onChange(e, props.subform, "audio")} />
          <Form.Check type="radio"
            name="editAudio"
            id="editAudioPitch"
            value="pitch"
            label="All editing allowed except pitch/formant"
            onChange={(e) => props.onChange(e, props.subform, "audio")} />
          <Form.Check type="radio"
            name="editAudio"
            id="editAudioYes"
            value="yes"
            label="All editing allowed"
            onChange={(e) => props.onChange(e, props.subform, "audio")} />
        </Form.Group>
        <Form.Group as={Col} md={6} lg={3}>
          <h5>Configuration files</h5>
          <p className='text-muted'>Such as OTO.ini, desc.mrq, frq, etc.</p>
          <Form.Check type="radio"
            name="editConfig"
            id="editConfigNo"
            value="no"
            label="No editing"
            onChange={(e) => props.onChange(e, props.subform, "config")} />
          <Form.Check type="radio"
            name="editConfig"
            id="editConfigYes"
            value="yes"
            label="Editing allowed"
            onChange={(e) => props.onChange(e, props.subform, "config")} />
        </Form.Group>
        <Form.Group as={Col} md={6} lg={3}>
          <h5>Porting to other synths</h5>
          <p className='text-muted'>Requiring file editing, conversion, or reconfiguration</p>
          <Form.Check type="radio"
            name="editPort"
            id="editPortNo"
            value="no"
            label="Prohibited"
            onChange={(e) => props.onChange(e, props.subform, "port")} />
          <Form.Check type="radio"
            name="editPort"
            id="editPortYes"
            value="yes"
            label="Allowed"
            onChange={(e) => props.onChange(e, props.subform, "port")} />
        </Form.Group>
        <Form.Group as={Col} md={6} lg={3}>
          <h5>Audio used outside of synths</h5>
          <p className='text-muted'>For videos, sound effects, etc.</p>
          <Form.Check type="radio"
            name="editProd"
            id="editProdNo"
            value="no"
            label="Prohibited"
            onChange={(e) => props.onChange(e, props.subform, "prod")} />
          <Form.Check type="radio"
            name="editProd"
            id="editProdYesLimit"
            value="limit"
            label="Usage of files in this folder only"
            onChange={(e) => props.onChange(e, props.subform, "prod")} />
          <Col className="ms-4 my-2">
            {folder}
          </Col>
          <Form.Check type="radio"
            name="editProd"
            id="editProdYes"
            value="yes"
            label="All usage allowed"
            onChange={(e) => props.onChange(e, props.subform, "prod")} />
        </Form.Group>
      </Form>
    </div>
  )
}

function RedistForm(props) {
  return (
    <div>
      <p>Can this voicebank or parts of it be shared with other users?</p>
      <Form>
        <h5>Voicebank</h5>
        <Row className="mb-3">
          <Form.Group as={Col} md={6}>
            <h6>Before discontinuation</h6>
            <Form.Check type="radio"
              name="shareVbBefore"
              id="shareVbBeforeNo"
              value="no"
              label="No sharing" 
              onChange={(e) => props.onChange(e, props.subform, "before")}/>
            <Form.Check type="radio"
              name="shareVbBefore"
              id="shareVbBeforeTransfer"
              value="transfer"
              label="Transfer/resell to one person only" 
              onChange={(e) => props.onChange(e, props.subform, "before")}/>
            <Form.Check type="radio"
              name="shareVbBefore"
              id="shareVbBeforeUnedit"
              value="unedit"
              label="Unedited voicebank only" 
              onChange={(e) => props.onChange(e, props.subform, "before")}/>
            <Form.Check type="radio"
              name="shareVbBefore"
              id="shareVbBeforeConfig"
              value="config"
              label="Configuration files only" 
              onChange={(e) => props.onChange(e, props.subform, "before")}/>
            <Form.Check type="radio"
              name="shareVbBefore"
              id="shareVbBeforeYes"
              value="yes"
              label="All sharing allowed" 
              onChange={(e) => props.onChange(e, props.subform, "before")}/>
          </Form.Group>
          <Form.Group as={Col} md={6}>
            <h6>After discontinuation</h6>
            <Form.Check type="radio"
              name="shareVbAfter"
              id="shareVbAfterNo"
              value="no"
              label="No sharing" 
              onChange={(e) => props.onChange(e, props.subform, "after")}/>
            <Form.Check type="radio"
              name="shareVbAfter"
              id="shareVbAfterTransfer"
              value="transfer"
              label="Transfer/resell to one person only" 
              onChange={(e) => props.onChange(e, props.subform, "after")}/>
            <Form.Check type="radio"
              name="shareVbAfter"
              id="shareVbAfterUnedit"
              value="unedit"
              label="Unedited voicebank only" 
              onChange={(e) => props.onChange(e, props.subform, "after")}/>
            <Form.Check type="radio"
              name="shareVbAfter"
              id="shareVbAfterConfig"
              value="config"
              label="Configuration files only" 
              onChange={(e) => props.onChange(e, props.subform, "after")}/>
            <Form.Check type="radio"
              name="shareVbAfter"
              id="shareVbAfterYes"
              value="yes"
              label="All sharing allowed" 
              onChange={(e) => props.onChange(e, props.subform, "after")}/>
          </Form.Group>
        </Row>
        <Form.Group>
          <h5>Rendered vocals</h5>
          <Form.Check type="radio"
            name="shareRender"
            id="shareRenderNo"
            value="no"
            label="No sharing" 
            onChange={(e) => props.onChange(e, props.subform, "render")}/>
          <Form.Check type="radio"
            name="shareRender"
            id="shareRenderYes"
            value="yes"
            label="Sharing allowed" 
            onChange={(e) => props.onChange(e, props.subform, "render")}/>
        </Form.Group>
      </Form>
    </div>
  )
}

class Preview extends Component {
  constructor(props) {
    super(props);
    this.makeTextFile = this.makeTextFile.bind(this);
  }

  makeTextFile(text) {
    text = text.replaceAll(/\n/g,"\r\n")
    const data = new Blob([text], { type: 'text/plain' });
    const textFile = window.URL.createObjectURL(data);
    return textFile;
  }

  render() {
    const button = typeof window == "undefined" ? (
      <Button as="button" className="text-white position-absolute start-50 translate-middle-x"
          download="readme.txt">
          Download readme.txt
        </Button>
    ) : (
      <Button as="button" className="text-white position-absolute start-50 translate-middle-x"
          download="readme.txt"
          href={this.makeTextFile(this.props.content)}>
          Download readme.txt
        </Button>
    )
    return (
      <div>
        <h2>Preview</h2>
        <Card className="px-2 mb-3">
          <pre className='mb-1'>{this.props.content}</pre>
        </Card>
        <div style={{height: "38px"}} className="mb-3">
          {button}
        </div>
      </div>
    );
  }
}